import axios from 'axios'
import ServiceHelper from '../helpers/service.helper.js'

export const listLabels = async (params={}) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/labels`, {
        headers: ServiceHelper.getHeaders(),
        params: params,
    })
    return response.data
}

