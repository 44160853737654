import React, { useState, useEffect } from 'react'
import { Typography, message, Col, Row } from 'antd'
import { Form, Picker, Input, Button, TextArea, Card } from 'antd-mobile';
import TestCode from '../../enums/testCode.enum.js'
import { addAppointmentBiometrics, listAppointmentBiometrics } from '../../services/appointment.service.js'
import { SaveOutlined, HeartOutlined, DashboardOutlined } from '@ant-design/icons';
import "./style.scss"

const { Text } = Typography
const { Item } = Form

export const BiometricForm = ({ patient, appointment, setAppointment, visible, setVisible, dates, setDates }) => {
  const [form] = Form.useForm()
  const [height, setHeight] = useState()
  const [isHeightVisible, setIsHeightVisible] = useState()
  const [isLoading, setIsLoading] = useState()

  const heightColumns = [
    Array.from(Array(7)).map((_, i) => {
      const feet = i+1
      return {
        label: `${feet}'`,
        value: feet
      }
    }),
    Array.from(Array(12)).map((_, i) => {
      return {
        label: `${i}"`,
        value: i
      }
    }),
  ]

  useEffect(() => {
    if (appointment) {
      fetchAppointmentBiometrics()
    }
  }, [appointment])

  const multipleFields = [TestCode.BP_S, TestCode.BP_D, TestCode.PULSE]

  const fetchAppointmentBiometrics = async () => {
    if (!appointment) return
    const { _id } = appointment
    
    try {
      
      const biometrics = await listAppointmentBiometrics(_id, patient._id)

      let params = {}
      
      for (const code of multipleFields) {
        const values = biometrics[code]
        if (values) {
          for (const [index] of biometrics[code].entries()) {
            if (!form.getFieldValue(`${code}${index}`) && values[index]) {
              params[`${code}${index}`] = values[index]
            }
          }
        }
      }

      if (TestCode.GRIP_RIGHT in biometrics && biometrics[TestCode.GRIP_RIGHT]?.length > 0 && !form.getFieldValue('gripStrengthRight')) {
        params['gripStrengthRight'] = biometrics[TestCode.GRIP_RIGHT][0]
      }

      if (TestCode.GRIP_LEFT in biometrics && biometrics[TestCode.GRIP_LEFT]?.length > 0 && !form.getFieldValue('gripStrengthLeft')) {
        params['gripStrengthLeft'] = biometrics[TestCode.GRIP_LEFT][0]
      }

      if (TestCode.WAIST in biometrics && biometrics[TestCode.WAIST]?.length > 0 && !form.getFieldValue('waistCircumference')) {
        params['waistCircumference'] = biometrics[TestCode.WAIST][0]
      }

      if (TestCode.WEIGHT in biometrics && biometrics[TestCode.WEIGHT]?.length > 0 && !form.getFieldValue('weight')) {
        params['weight'] = biometrics[TestCode.WEIGHT][0]
      }

      if (TestCode.HEIGHT in biometrics && biometrics[TestCode.HEIGHT]?.length > 0 && biometrics[TestCode.HEIGHT][0]) {
        params['height'] = biometrics[TestCode.HEIGHT][0]
      }

      if (!params['height'] && !form.getFieldValue('height') && patient.height) {
        params['height'] = patient.height
      }

      if (params['height']) {
        setHeight(formatHeight(params['height']))
      }

      if ('note' in biometrics && biometrics.note.text && !form.getFieldValue('note')) {
        params['note'] = biometrics.note.text
      }

      form.setFieldsValue(params)
    } 

    catch(error) {
      console.log(error)
    }
  }

  const formatHeight = value => {
    if (!value) return null
    const feet = Math.floor(value/12)
    const inches = value%12
    return `${feet}' ${inches}"`
  }

  const onFinish = async () => {
    setIsLoading(true)
    try {
      const formValues = form.getFieldsValue()
      const {
        height,
        waistCircumference,
        weight,
        gripStrengthRight,
        gripStrengthLeft,
        note
      } = formValues

      let params = {
        [TestCode.HEIGHT]: height ? height[0]*12 + height[1] : null,
        [TestCode.WAIST]: waistCircumference ? Number(waistCircumference) : null,
        [TestCode.WEIGHT]: weight ? Number(weight) : null,
        [TestCode.GRIP_RIGHT]: gripStrengthRight ? Number(gripStrengthRight): null,
        [TestCode.GRIP_LEFT]: gripStrengthLeft ? Number(gripStrengthLeft): null,
        [TestCode.BP_D]: [],
        [TestCode.BP_S]: [],
        [TestCode.PULSE]: [],
        note: note
      }

      for (const code of multipleFields) {
        for (const [index] of Array.from(Array(3)).entries()) {
          params[code].push(formValues[`${code}${index}`] ? Number(formValues[`${code}${index}`]) : null)
        }
      }

      const biometricAppointment = await addAppointmentBiometrics(appointment._id, patient._id, params)
      setDates(dates.map(({ date, appointments }) => {
        return {
          date,
          appointments: appointments.map(a => {
            return a._id === biometricAppointment._id ? biometricAppointment : a
          })
        }
      }))

      onClose()
      const msg = `Biometrics saved for ${patient.firstName} ${patient.lastName}`
      message.success(msg)
    } catch (err) {
      console.log(err)
      message.error('Failed to save biometrics')
    }

    setIsLoading(false)
  }

  const resetValues = () => {
    form.resetFields()
  }

  const onClose = () => {
    setVisible(false)
    setHeight(null)
    setAppointment(null)
    resetValues()
  }

  return appointment ? (
    <Form
      form={form}
      onFinish={onFinish}
      layout="horizontal"
      className="biometric-form"
    >
      <Card title={<><DashboardOutlined /> Basic Measurements</>}>
        <Item
          label="Height"
          name="height"
        >
          <Button
            onClick={() => setIsHeightVisible(true)}
            className="height-btn"
          >
            {height || 'Select Height'}
          </Button>
          <Picker
            columns={heightColumns}
            visible={isHeightVisible}
            onClose={() => setIsHeightVisible(false)}
            cancelText='Cancel'
            confirmText='Confirm'
            onConfirm={val => {
              form.setFieldsValue({ height: val })
              setHeight(`${val[0]}' ${val[1]}"`)
            }}
          />
        </Item>

        <Item
          label="Weight"
          name="weight"
        >
          <Input
            placeholder='lb' 
            type="text"
            pattern="[0-9]*\.?[0-9]*"
            inputMode="decimal"

          />
        </Item>

        <Item
          label="Waist"
          name="waistCircumference"
        >
          <Input
            placeholder='in' 
            type="text"
            pattern="[0-9]*\.?[0-9]*"
            inputMode="decimal"
          />
        </Item>
      </Card>

      <Card title={<><HeartOutlined /> Grip Strength</>}>
        <Item
          label="Right hand"
          name="gripStrengthRight"
        >
          <Input
            placeholder='lbs' 
            type="text"
            pattern="[0-9]*\.?[0-9]*"
            inputMode="decimal"

          />
        </Item>
        <Item
          label="Left hand"
          name="gripStrengthLeft"
        >
          <Input
            placeholder='lbs' 
            type="text"
            pattern="[0-9]*\.?[0-9]*"
            inputMode="decimal"

          />
        </Item>
      </Card>

      <Card title={<><HeartOutlined /> BP Reading #1</>}>
        <Item label="Blood Pressure">
          <Row align="middle" style={{padding:0}}>
            <Col span={10}>
              <Item name={`${TestCode.BP_S}0`}>                 
                <Input 
                  placeholder='mmHg'
                  type="text"
                  pattern="[0-9]*\.?[0-9]*"
                  inputMode="decimal"
                />    
              </Item>
            </Col>
            <Col span={2} className="bp-slash">/</Col>
            <Col span={10}>
              <Item name={`${TestCode.BP_D}0`}>
                <Input
                  placeholder='mmHg' 
                  type="text"
                  pattern="[0-9]*\.?[0-9]*"
                  inputMode="decimal"
                />     
              </Item>  
            </Col>
          </Row>
        </Item>
        <Item
          label="Pulse"
          name={`${TestCode.PULSE}0`}
        >
          <Input
            placeholder='bpm' 
            type="text"
            pattern="[0-9]*\.?[0-9]*"
            inputMode="decimal"
          />
        </Item>
      </Card>

      <Card title={<><HeartOutlined /> BP Reading #2</>}>
        <Item label="Blood Pressure">
          <Row align="middle">
            <Col span={10}>
              <Item name={`${TestCode.BP_S}1`}>                 
                <Input 
                  placeholder='mmHg'
                  type="text"
                  pattern="[0-9]*\.?[0-9]*"
                  inputMode="decimal"
                />    
              </Item>
            </Col>
            <Col span={2} className="bp-slash">/</Col>
            <Col span={10}>
              <Item name={`${TestCode.BP_D}1`}>
                <Input
                  placeholder='mmHg' 
                  type="text"
                  pattern="[0-9]*\.?[0-9]*"
                  inputMode="decimal"
                />     
              </Item>  
            </Col>
          </Row>
        </Item>
        <Item
          label="Pulse"
          name={`${TestCode.PULSE}1`}
        >
          <Input
            placeholder='bpm' 
            type="text"
            pattern="[0-9]*\.?[0-9]*"
            inputMode="decimal"
          />
        </Item>
      </Card>

      <Card title={<><HeartOutlined /> BP Reading #3</>}>
        <Item label="Blood Pressure">
          <div className="bp-container">
            <Item name={`${TestCode.BP_S}2`} className="bp-input">                 
              <Input 
                placeholder='mmHg'
                type="text"
                pattern="[0-9]*\.?[0-9]*"
                inputMode="decimal"
              />    
            </Item>
            <span className="bp-slash">/</span>
            <Item name={`${TestCode.BP_D}2`} className="bp-input">
              <Input
                placeholder='mmHg' 
                type="text"
                pattern="[0-9]*\.?[0-9]*"
                inputMode="decimal"
              />     
            </Item>  
          </div>
        </Item>
        <Item
          label="Pulse"
          name={`${TestCode.PULSE}2`}
        >
          <Input
            placeholder='bpm' 
            type="text"
            pattern="[0-9]*\.?[0-9]*"
            inputMode="decimal"
          />
        </Item>
      </Card>

      <Card title="Patient Notes">
        <Item 
          name="note"
        >
          <TextArea 
            placeholder="Write note here ..."/>
        </Item>
      </Card>

      <div className="modal-footer">
        <div className="button-stack">
          <Button
            color='primary'
            type="submit"
            loading={isLoading}
            className="confirm-btn"
            block
            size="large"
          >
            <SaveOutlined style={{ marginRight: 8 }}/> Save
          </Button>

          <Button
            className="cancel-btn"
            onClick={onClose}
            block
            size="large"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  ) : null
}