import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Form, Button, Input } from 'antd-mobile';
import { message } from 'antd'
import { login } from '../../services/auth.service.js'
import { UserContext } from '../../contexts/user.context.js';
import './style.scss'

const { Item } = Form

export const Login = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState()
    const { setIsAuthed } = useContext(UserContext)

    const onFinish = async (values) => {
        setIsLoading(true)
        try {
            await login(values)
            setIsAuthed(true)
            message.info('Logged in')
            navigate('/appointments')
        } catch (err) {
            console.log('err', err)
            message.error('Failed to log in')
        }
        setIsLoading(false)
    };

    return <div className="login-component">
        <h3 className="login-title">Welcome Back!</h3>
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className="login-form"
        >
            <Item 
                label="Email Address"
                name="email"
                rules={[{ 
                    required: true, 
                    message: 'Enter email address'
                }]}
            >
                <Input placeholder="Email Address" />
            </Item>

            <Item 
                label="Password"
                name="password"
                rules={[{ 
                    required: true, 
                    message: 'Enter password'
                }]}
            >
                <Input placeholder="Password" type="password" />
            </Item>

            <Button 
                color="primary" 
                type="submit"
                loading={isLoading}
                className="login-button"
            >
                Log in
            </Button>
        </Form>
    </div>
}